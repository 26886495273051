import { React, useState } from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import MaterialReactTable from "material-react-table";
import {
  useGetCountriesQuery,
  useGetUserQuery,
  useEditPepMutation
} from "state/api";

const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
const userId = storedValue?._id;
const PepRisk = () => {
  //Edit ID type mutation edit PepMutation
  const [editPep, { isLoading, isError }] = useEditPepMutation();

  const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);
  console.log(data);
  const handleSave = async ({ values, table, row, exitEditingMode }) => {
    console.log(values);
    console.log(row.original); //exit editing mode
    await editPep({ ...row.original, ...values });

    exitEditingMode();
  };

  const columns = [
    {
      accessorKey: "status",
      header: "Type",
      enableEditing: false,
    },

    {
      accessorKey: "logic", // Assuming 'risk' is the key for the Risk Level
      header: "Risk Level",
      Cell: ({ cell }) => cell.getValue(),
      enableEditing: false,
      editVariant: "select",
      editSelectOptions: ["Low", "Medium", "High"],
    },
    {
      accessorKey: "RiskScore",
      header: "Risk Score",
      type: "number", // Specify the type for proper input handling
      Cell: ({ cell }) => cell.getValue(),
      // Enable editing for this column
      enableEditing: true,
      editVariant: "select",
      editSelectOptions: [1, 2, 3, 4],
    },
  ];

  if (isLoading || isLoadingCountries) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="PEP Risk" subtitle="RBA Definition PEP Risk" />
      <Box sx={{ height: 400, width: "100%" }}>
        <MaterialReactTable
          columns={columns}
          data={data.pep}
          enableEditing
          editingMode="row"
          onEditingRowSave={handleSave}
        />
      </Box>
    </Box>
  );
};

export default PepRisk;
