// export const APIURL = "https://amldiligence.com/api/";

export const APIURL = "https://amldiligence.com/api/"; //"https://amldiligence.com/api/"; http://localhost:5001


//Add entities : Remove Business address and put Legal Name on whole row,

//remove checks from fields on add entity
//shareholder/UBO in the add entity form

//Add sections to Compliance checks view. remove save button instead add print

// formatting in excel export remove empty columns and add spaces in phone number date format to be actual
// Auto Scan only system cleared on a daily basis, also reflect in log

//1. Website

<report>
  {/* STATIC */}
  <rentity_id>16847</rentity_id>
  <submission_code>E</submission_code>
  <report_code>DPMSR</report_code>
  <entity_reference>CPV 1849, 1850</entity_reference>
  <submission_date>2024-04-01T00:00:00</submission_date>
  <currency_code_local>AED</currency_code_local>
  <reporting_person>
    <gender>M</gender>
    <title>Mr</title>
    <first_name>Aswat Juned</first_name>
    <last_name>Motiwala</last_name>
    <birthdate>1987-04-06T00:00:00</birthdate>
    <ssn>784-1987-5949869-8</ssn>
    <nationality1>IN</nationality1>
    <phones>
      <phone>
        <tph_contact_type>PRSNL</tph_contact_type>
        <tph_communication_type>M</tph_communication_type>
        <tph_country_prefix>971</tph_country_prefix>
        <tph_number>562777759</tph_number>
      </phone>
    </phones>
    <addresses>
      <address>
        <address_type>BU</address_type>
        <address>SHOP NO 1, SIKKA 28 STREET, ALDHAGAY, GOLD SOUK</address>
        <city>DEIRA</city>
        <country_code>AE</country_code>
        <state>DUBAI</state>
      </address>
    </addresses>
    <email>babatexgeneraltrading@gmail.com</email>
    <occupation>BUSINESMAN</occupation>
  </reporting_person>
  <location>
    <address_type>BU</address_type>
    <address>SHOP NO 1, SIKKA 28 STREET, ALDHAGAY, GOLD SOUK</address>
    <city>DEIRA</city>
    <country_code>AE</country_code>
    <state>DUBAI</state>
  </location>
  <reason>REPORTING DPMSR TO FIU AS PER MOE MANDATE</reason>
  <activity>
    <report_parties>
      <report_party>
        <entity>
          <name>GIOVANNI JEWELLERY LLC</name>
          <incorporation_legal_form>WJDPS</incorporation_legal_form>
          <incorporation_number>548019</incorporation_number>
          <incorporation_country_code>AE</incorporation_country_code>
          <director_id>
            <first_name>KHALIL</first_name>
            <last_name>BOUZ</last_name>
            <birthdate>1974-10-01T00:00:00</birthdate>
            <id_number>784-1974-5103190-6</id_number>
            <identification>
              <type>EID</type>
              <number>784-1974-5103190-6</number>
              <issue_date>2021-12-27T00:00:00</issue_date>
              <expiry_date>2031-12-26T00:00:00</expiry_date>
              <issue_country>AE</issue_country>
            </identification>
            <role>ATR</role>
          </director_id>
          <incorporation_date>2003-08-13T00:00:00</incorporation_date>
          <tax_reg_number>N</tax_reg_number>
        </entity>
        <reason>CASH TRANSACTION MORE THAN AED 55,000</reason>
      </report_party>
    </report_parties>
    <goods_services>
      <item>
        <item_type>GOLD</item_type>
        <status_comments>CASH PAYMENT AED 3,980,000</status_comments>
        <disposed_value>3980000</disposed_value>
        <currency_code>AED</currency_code>
      </item>
      <item>
        <item_type>GOLD</item_type>
        <status_comments>CASH PAID AED 140,000</status_comments>
        <disposed_value>140000</disposed_value>
        <currency_code>AED</currency_code>
      </item>
    </goods_services>
  </activity>
  <report_indicators>
    <indicator>DPMSJ</indicator>
  </report_indicators>
</report>;

<report>
  <rentity_id>16847</rentity_id>
  <submission_code>E</submission_code>
  <report_code>DPMSR</report_code>
  <entity_reference>CPV 1849, 1850</entity_reference>
  <submission_date>2024-04-01T00:00:00</submission_date>
  <currency_code_local>AED</currency_code_local>
  <reporting_person>
    <gender>M</gender>
    <title>Mr</title>
    <first_name>Aswat Juned</first_name>
    <last_name>Motiwala</last_name>
    <birthdate>1987-04-06T00:00:00</birthdate>
    <ssn>784-1987-5949869-8</ssn>
    <nationality1>IN</nationality1>
    <phones>
      <phone>
        <tph_contact_type>PRSNL</tph_contact_type>
        <tph_communication_type>M</tph_communication_type>
        <tph_country_prefix>971</tph_country_prefix>
        <tph_number>562777759</tph_number>
      </phone>
    </phones>
    <addresses>
      <address>
        <address_type>BU</address_type>
        <address>SHOP NO 1, SIKKA 28 STREET, ALDHAGAY, GOLD SOUK</address>
        <city>DEIRA</city>
        <country_code>AE</country_code>
        <state>DUBAI</state>
      </address>
    </addresses>
    <email>babatexgeneraltrading@gmail.com</email>
    <occupation>BUSINESMAN</occupation>
  </reporting_person>
  <location>
    <address_type>BU</address_type>
    <address>SHOP NO 1, SIKKA 28 STREET, ALDHAGAY, GOLD SOUK</address>
    <city>DEIRA</city>
    <country_code>AE</country_code>
    <state>DUBAI</state>
  </location>
  <reason>REPORTING DPMSR TO FIU AS PER MOE MANDATE</reason>
  <activity>
    <report_parties>
      <report_party>
        <entity>
          <name>GIOVANNI JEWELLERY LLC</name>
          <incorporation_legal_form>WJDPS</incorporation_legal_form>
          <incorporation_number>548019</incorporation_number>
          <incorporation_country_code>AE</incorporation_country_code>
          <director_id>
            <first_name>KHALIL</first_name>
            <last_name>BOUZ</last_name>
            <birthdate>1974-10-01T00:00:00</birthdate>
            <id_number>784-1974-5103190-6</id_number>
            <identification>
              <type>EID</type>
              <number>784-1974-5103190-6</number>
              <issue_date>2021-12-27T00:00:00</issue_date>
              <expiry_date>2031-12-26T00:00:00</expiry_date>
              <issue_country>AE</issue_country>
            </identification>
            <role>ATR</role>
          </director_id>
          <incorporation_date>2003-08-13T00:00:00</incorporation_date>
          <tax_reg_number>N</tax_reg_number>
        </entity>
        <reason>CASH TRANSACTION MORE THAN AED 55,000</reason>
      </report_party>
    </report_parties>
    <goods_services>
      <item>
        <item_type>GOLD</item_type>
        <status_comments>CASH PAYMENT AED 3,980,000</status_comments>
        <disposed_value>3980000</disposed_value>
        <currency_code>AED</currency_code>
      </item>
      <item>
        <item_type>GOLD</item_type>
        <status_comments>CASH PAID AED 140,000</status_comments>
        <disposed_value>140000</disposed_value>
        <currency_code>AED</currency_code>
      </item>
    </goods_services>
  </activity>
  <report_indicators>
    <indicator>DPMSJ</indicator>
  </report_indicators>
</report>;

//////////

<report>
  <rentity_id>16847</rentity_id> {/* STATIC */}
  <submission_code>E</submission_code>
  <report_code>DPMSR</report_code>
  <entity_reference>CPV 1849, 1850</entity_reference>
  <submission_date>2024-04-01T00:00:00</submission_date>
  <currency_code_local>AED</currency_code_local>
  <reporting_person>
    {/* STATIC */}
    <gender>M</gender>
    <title>Mr</title> {/* STATIC */}
    <first_name>Aswat Juned</first_name> {/* STATIC */}
    <last_name>Motiwala</last_name> {/* STATIC */}
    <birthdate>1987-04-06T00:00:00</birthdate> {/* STATIC */}
    <ssn>784-1987-5949869-8</ssn> {/* STATIC */}
    <nationality1>IN</nationality1> {/* STATIC */}
    <phones>
      <phone>
        <tph_contact_type>PRSNL</tph_contact_type> {/* STATIC */}
        <tph_communication_type>M</tph_communication_type> {/* STATIC */}
        <tph_country_prefix>971</tph_country_prefix> {/* STATIC */}
        <tph_number>562777759</tph_number> {/* STATIC */}
      </phone>
    </phones>
    <addresses>
      <address>
        <address_type>BU</address_type> {/* STATIC */}
        <address>SHOP NO 1, SIKKA 28 STREET, ALDHAGAY, GOLD SOUK</address>{" "}
        {/* STATIC */}
        <city>DEIRA</city> {/* STATIC */}
        <country_code>AE</country_code> {/* STATIC */}
        <state>DUBAI</state> {/* STATIC */}
      </address>
    </addresses>
    <email>babatexgeneraltrading@gmail.com</email> {/* STATIC */}
    <occupation>BUSINESMAN</occupation> {/* STATIC */}
  </reporting_person>
  <location>
    <address_type>BU</address_type> {/* STATIC */}
    <address>SHOP NO 1, SIKKA 28 STREET, ALDHAGAY, GOLD SOUK</address>{" "}
    {/* STATIC */}
    <city>DEIRA</city> {/* STATIC */}
    <country_code>AE</country_code> {/* STATIC */}
    <state>DUBAI</state> {/* STATIC */}
  </location>
  <reason>REPORTING DPMSR TO FIU AS PER MOE MANDATE</reason> {/* STATIC */}
  <activity>
    <report_parties>
      <report_party>
        <entity>
          <name>GIOVANNI JEWELLERY LLC</name>
          <incorporation_legal_form>WJDPS</incorporation_legal_form>
          <incorporation_number>548019</incorporation_number>
          <incorporation_country_code>AE</incorporation_country_code>
          <director_id>
            <first_name>KHALIL</first_name>
            <last_name>BOUZ</last_name>
            <birthdate>1974-10-01T00:00:00</birthdate>
            <id_number>784-1974-5103190-6</id_number>
            <identification>
              <type>EID</type>
              <number>784-1974-5103190-6</number>
              <issue_date>2021-12-27T00:00:00</issue_date>
              <expiry_date>2031-12-26T00:00:00</expiry_date>
              <issue_country>AE</issue_country>
            </identification>
            <role>ATR</role>
          </director_id>
          <incorporation_date>2003-08-13T00:00:00</incorporation_date>
          <tax_reg_number>N</tax_reg_number>
        </entity>
        <reason>CASH TRANSACTION MORE THAN AED 55,000</reason> {/* STATIC */}
      </report_party>
    </report_parties>
    <goods_services>
      <item>
        <item_type>GOLD</item_type>
        <status_comments>CASH PAYMENT AED 3,980,000</status_comments>
        <disposed_value>3980000</disposed_value>
        <currency_code>AED</currency_code>
      </item>
      <item>
        <item_type>GOLD</item_type>
        <status_comments>CASH PAID AED 140,000</status_comments>
        <disposed_value>140000</disposed_value>
        <currency_code>AED</currency_code>
      </item>
    </goods_services>
  </activity>
  <report_indicators>
    <indicator>DPMSJ</indicator>
  </report_indicators>
</report>;

//////////////////////////////////////

<report>
  <rentity_id>16847</rentity_id>
  <submission_code>E</submission_code>
  <report_code>DPMSR</report_code>
  <entity_reference>CPV 1849, 1850</entity_reference>
  <submission_date>2024-04-01T00:00:00</submission_date>
  <currency_code_local>AED</currency_code_local>
  <reporting_person>
    <gender>M</gender>
    <title>Mr</title>
    <first_name>Aswat Juned</first_name>
    <last_name>Motiwala</last_name>
    <birthdate>1987-04-06T00:00:00</birthdate>
    <ssn>784-1987-5949869-8</ssn>
    <nationality1>IN</nationality1>
    <phones>
      <phone>
        <tph_contact_type>PRSNL</tph_contact_type>
        <tph_communication_type>M</tph_communication_type>
        <tph_country_prefix>971</tph_country_prefix>
        <tph_number>562777759</tph_number>
      </phone>
    </phones>
    <addresses>
      <address>
        <address_type>BU</address_type>
        <address>SHOP NO 1, SIKKA 28 STREET, ALDHAGAY, GOLD SOUK</address>
        <city>DEIRA</city>
        <country_code>AE</country_code>
        <state>DUBAI</state>
      </address>
    </addresses>
    <email>babatexgeneraltrading@gmail.com</email>
    <occupation>BUSINESMAN</occupation>
  </reporting_person>
  <location>
    <address_type>BU</address_type>
    <address>SHOP NO 1, SIKKA 28 STREET, ALDHAGAY, GOLD SOUK</address>
    <city>DEIRA</city>
    <country_code>AE</country_code>
    <state>DUBAI</state>
  </location>
  <reason>REPORTING DPMSR TO FIU AS PER MOE MANDATE</reason>
  <activity>
    <report_parties>
      <report_party>
        <entity>
          <name>GIOVANNI JEWELLERY LLC</name>
          <incorporation_legal_form>WJDPS</incorporation_legal_form>
          <incorporation_number>548019</incorporation_number>
          <incorporation_country_code>AE</incorporation_country_code>
          <director_id>
            <first_name>KHALIL</first_name>
            <last_name>BOUZ</last_name>
            <birthdate>1974-10-01T00:00:00</birthdate>
            <id_number>784-1974-5103190-6</id_number>
            <identification>
              <type>EID</type>
              <number>784-1974-5103190-6</number>
              <issue_date>2021-12-27T00:00:00</issue_date>
              <expiry_date>2031-12-26T00:00:00</expiry_date>
              <issue_country>AE</issue_country>
            </identification>
            <role>ATR</role>
          </director_id>
          <incorporation_date>2003-08-13T00:00:00</incorporation_date>
          <tax_reg_number>N</tax_reg_number>
        </entity>
        <reason>CASH TRANSACTION MORE THAN AED 55,000</reason>
      </report_party>
    </report_parties>
    <goods_services>
      <item>
        <item_type>GOLD</item_type>
        <status_comments>CASH PAYMENT AED 3,980,000</status_comments>
        <disposed_value>3980000</disposed_value>
        <currency_code>AED</currency_code>
      </item>
      <item>
        <item_type>GOLD</item_type>
        <status_comments>CASH PAID AED 140,000</status_comments>
        <disposed_value>140000</disposed_value>
        <currency_code>AED</currency_code>
      </item>
    </goods_services>
  </activity>
  <report_indicators>
    <indicator>DPMSJ</indicator>
  </report_indicators>
</report>;
