export function transformShareHolders(shareHolders) {
  const newShareHolders = {
    fullLegalName: shareHolders.fullName,
    occupation: shareHolders.occupation,
    dualNationality: shareHolders.dualNationality,
    nationality: shareHolders.nationality,
    idDoc: {
      type: shareHolders.idType,
      issueCountry: shareHolders.country,
      number: shareHolders.number,
      issueDate: shareHolders.issueDate,
      expiryDate: shareHolders.expiryDate,
    },
    doB: shareHolders.birthDate,
    residentialStatus: shareHolders.residentialStatus,
    percentShares: shareHolders.percentShares,
    contactInfo: {
      contactType: shareHolders.contactType,
      contactCountry: shareHolders.contactCountry,
      contactNumber: shareHolders.contactNumber,
    },
    isAuthorizedSignatory: shareHolders.authorizedSignatory,
    ubo: shareHolders.ubo,
    uboReason: shareHolders.uboReason,
    uboDate: shareHolders.uboDate,
    pep:shareHolders.pep,
    pepReason: shareHolders.pepReason
  };

  return newShareHolders;
}

export function transformData(initialValues) {
  const newInitialValues = {
    _id: initialValues._id,
    fullLegalName: initialValues.legalName,
    registeredAddress: initialValues.registeredAddress,
    businessAddress: initialValues.businessAddress,
    jurisdiction: initialValues.jurisdiction,
    sourceOfFunds: initialValues.sourceOfFunds,
    taxNo: initialValues.taxNo,
    deliveryChannel: initialValues.deliveryChannel,
    TradeLicenseInfo: {
      businessActivity: initialValues.businessActivity,
      tradeLicenseNo: initialValues.tradeLicenseNo,
      countryofIncorporation: initialValues.countryofIncorporation,
      licensingAuthority: initialValues.licensingAuthority,
      incoroprationDate: initialValues.incoroprationDate,
      licenseIssueDate: initialValues.licenseIssueDate,
      licenseExpiry: initialValues.licenseExpiry,
    },
    contactInfo: {
      contactType: initialValues.contactType,
      contactCountry: initialValues.contactCountry,
      contactNumber: initialValues.contactNumber,
      emailAddress: initialValues.emailAddress,
      POBox: initialValues.POBox,
      website: initialValues.website,
    },
    financialInfo: {
      bankName: initialValues.bankName,
      accountNo: initialValues.accountNo,
      accountName: initialValues.accountName,
      iban: initialValues.iban,
      bicSwift: initialValues.bicSwift,
      currency: initialValues.currency,
      yearOfRelationship: initialValues.yearOfRelationship,
      branch: initialValues.branch,
      anualBusiness: initialValues.anualBusiness,
      totalShareCapital: initialValues.totalShareCapital,
      totalShareHolderEquity: initialValues.totalShareHolderEquity,
      totalAssets: initialValues.totalAssets,
      totalAnualSales: initialValues.totalAnualSales,
    },
    shareHolders: initialValues.shareHolders,
  };

  return newInitialValues;
}

export function isAllFieldsFilled(obj) {
  return Object.values(obj ?? {}).every((value) => value);
}

export function isComplianceQFilled(value) {
  if (value === null || value === false) {
    return false; // explicit check for null values
  }
  if (typeof value === "string") {
    return value.trim() !== ""; // checking for non-empty strings
  }
  if (Array.isArray(value)) {
    return value.length > 0; // checking for non-empty arrays
  }
  if (typeof value === "object") {
    for (let key in value) {
      if (!isAllFieldsFilled(value[key])) {
        return false; // if any nested field is not filled, return false
      }
    }
  }
  return true;
}

// export function isComplianceQFilled(value) {
//   if (
//     value === null ||
//     value === undefined ||
//     value === true ||
//     value === false
//   ) {
//     return false; // explicit check for null or undefined values
//   }
//   if (typeof value === "string") {
//     return value.trim() !== ""; // checking for non-empty strings
//   }
//   if (Array.isArray(value)) {
//     return value.length > 0; // checking for non-empty arrays
//   }
//   if (typeof value === "object") {
//     for (let key in value) {
//       if (!isComplianceQFilled(value[key])) {
//         return false; // if any nested field is not filled, return false
//       }
//     }
//     // Check for empty objects
//     return Object.keys(value).length > 0;
//   }
//   return true; // for other data types like numbers, booleans, etc.
// }
