// // import React, { useMemo } from "react";
// // import { ResponsiveLine } from "@nivo/line";
// // import {
// //   useTheme,
// //   Box,
// //   Typography,
// //   Divider,
// //   Button,
// //   IconButton,
// // } from "@mui/material";
// // import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// // import { styled } from "@mui/material/styles";

// // const OverviewChart = ({ isDashboard = false, view }) => {
// //   const theme = useTheme();
// //   const VisuallyHiddenInput = styled("input")({
// //     clip: "rect(0 0 0 0)",
// //     clipPath: "inset(50%)",
// //     height: 1,
// //     overflow: "hidden",
// //     position: "absolute",
// //     bottom: 0,
// //     left: 0,
// //     whiteSpace: "nowrap",
// //     width: 1,
// //   });

// //   const h5Size = theme.typography.h5.fontSize;

// //   return (
// //     <Box>
// //       <Typography
// //         variant="h4"
// //         color={theme.palette.secondary[300]}
// //         fontWeight="bold"
// //       >
// //         Entity Overall Compliance Checks:
// //       </Typography>
// //       <ol style={{ fontSize: h5Size, padding: "5px" }}>
// //         <li>
// //           <span>
// //             {" "}
// //             <Typography variant="h5" padding={"10px"}>
// //               Overall entity vide risk assessment{" "}
// //               <Button
// //                 component="label"
// //                 variant="contained"
// //                 startIcon={<CloudUploadIcon />}
// //               >
// //                 <VisuallyHiddenInput type="file" />
// //               </Button>
// //             </Typography>{" "}
// //           </span>
// //         </li>
// //         <Divider />
// //         <li>
// //           {" "}
// //           <Typography variant="h5" padding={"10px"}>
// //             Policies and procedures{" "}
// //             <Button
// //               component="label"
// //               variant="contained"
// //               startIcon={<CloudUploadIcon />}
// //             >
// //               <VisuallyHiddenInput type="file" />
// //             </Button>
// //           </Typography>
// //         </li>{" "}
// //         <Divider />
// //         <li>
// //           {" "}
// //           <Typography variant="h5" padding={"10px"}>
// //             Training and awareness{" "}
// //             <Button
// //               component="label"
// //               variant="contained"
// //               startIcon={<CloudUploadIcon />}
// //             >
// //               <VisuallyHiddenInput type="file" />
// //             </Button>
// //           </Typography>
// //         </li>{" "}
// //         <Divider />
// //         <li>
// //           {" "}
// //           <Typography variant="h5" padding={"10px"}>
// //             Shareholder/ UBO register{" "}
// //             <Button
// //               component="label"
// //               variant="contained"
// //               startIcon={<CloudUploadIcon />}
// //             >
// //               <VisuallyHiddenInput type="file" />
// //             </Button>
// //           </Typography>
// //         </li>{" "}
// //         <Divider />
// //         <li>
// //           {" "}
// //           <Typography variant="h5" padding={"10px"}>
// //             OECD compliance
// //             <Button
// //               component="label"
// //               variant="contained"
// //               startIcon={<CloudUploadIcon />}
// //             >
// //               <VisuallyHiddenInput type="file" />
// //             </Button>
// //           </Typography>
// //         </li>
// //       </ol>
// //     </Box>
// //   );
// // };

// // export default OverviewChart;

// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   Typography,
//   CircularProgress,
//   Divider,
//   IconButton,
// } from "@mui/material";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { useUploadFileMutation } from "../state/api";

// const FileUploadItem = ({ title }) => {
//   const [file, setFile] = useState(null);
//   const [uploadSuccess, setUploadSuccess] = useState(false);
//   const [uploadFile, { isLoading }] = useUploadFileMutation();

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleUpload = async () => {
//     try {
//       await uploadFile(file).unwrap();
//       alert("File uploaded successfully!");
//       setUploadSuccess(true);
//     } catch (error) {
//       alert("File upload failed!");
//       setUploadSuccess(false);
//     }
//   };

//   return (
//     <li style={{ listStyleType: "none" }}>
//       <Typography variant="h5">{title} </Typography>
//       <Box display="flex" flexDirection="row" alignItems="start">
//         <Button
//           component="label"
//           variant="contained"
//           startIcon={<CloudUploadIcon />}
//         >
//           Upload
//           <input type="file" hidden onChange={handleFileChange} />
//         </Button>
//         <Button variant="contained" onClick={handleUpload} disabled={isLoading}>
//           Submit
//         </Button>
//         {isLoading && <CircularProgress />}
//         <IconButton disabled={!uploadSuccess}>
//           <VisibilityIcon />
//         </IconButton>
//       </Box>
//       <Divider />
//     </li>
//   );
// };

// const OverviewChart = () => {
//   return (
//     <Box>
//       <Typography variant="h4">Entity Overall Compliance Checks:</Typography>
//       <ol>
//         <FileUploadItem title="Overall entity wide risk assessment" />
//         <FileUploadItem title="Policies and procedures" />
//         <FileUploadItem title="Training and awareness" />
//         <FileUploadItem title="Shareholder/ UBO register" />
//         <FileUploadItem title="OECD compliance" />
//       </ol>
//     </Box>
//   );
// };

// export default OverviewChart;

// import React from "react";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import UploadIcon from "@mui/icons-material/CloudUpload";
// import DownloadIcon from "@mui/icons-material/CloudDownload";
// import ViewIcon from "@mui/icons-material/Visibility";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { IconButton, useTheme } from "@mui/material";

// const OverviewChart = () => {
//   const theme = useTheme();

//   return (
//     <Box>
//       <Typography
//         variant="h4"
//         paddingBottom={2}
//         style={{ color: theme.palette.secondary[300] }}
//         fontWeight="bold"
//       >
//         Entity Overall Compliance Checks:
//       </Typography>

//       <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
//         <Typography
//           sx={{ flex: 1 }}
//           style={{ color: theme.palette.secondary[300] }}
//         >
//           Overall entity vide risk assessment
//         </Typography>
//         <IconButton aria-label="upload">
//           <UploadIcon />
//         </IconButton>
//         <IconButton aria-label="download">
//           <DownloadIcon />
//         </IconButton>
//         <IconButton aria-label="view">
//           <ViewIcon />
//         </IconButton>
//         <IconButton aria-label="delete">
//           <DeleteIcon />
//         </IconButton>
//       </Box>

//       <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
//         <Typography
//           sx={{ flex: 1 }}
//           style={{ color: theme.palette.secondary[300] }}
//         >
//           Policies and procedures
//         </Typography>
//         <IconButton aria-label="upload">
//           <UploadIcon />
//         </IconButton>
//         <IconButton aria-label="download">
//           <DownloadIcon />
//         </IconButton>
//         <IconButton aria-label="view">
//           <ViewIcon />
//         </IconButton>
//         <IconButton aria-label="delete">
//           <DeleteIcon />
//         </IconButton>
//       </Box>

//       <Box
//         sx={{ display: "flex", alignItems: "center", mb: 2 }}
//         style={{ color: theme.palette.secondary[300] }}
//       >
//         <Typography sx={{ flex: 1 }}>Training and awareness</Typography>
//         <IconButton aria-label="upload">
//           <UploadIcon />
//         </IconButton>
//         <IconButton aria-label="download">
//           <DownloadIcon />
//         </IconButton>
//         <IconButton aria-label="view">
//           <ViewIcon />
//         </IconButton>
//         <IconButton aria-label="delete">
//           <DeleteIcon />
//         </IconButton>
//       </Box>

//       <Box
//         sx={{ display: "flex", alignItems: "center", mb: 2 }}
//         style={{ color: theme.palette.secondary[300] }}
//       >
//         <Typography sx={{ flex: 1 }}>Shareholder/ UBO register</Typography>
//         <IconButton aria-label="upload">
//           <UploadIcon />
//         </IconButton>
//         <IconButton aria-label="download">
//           <DownloadIcon />
//         </IconButton>
//         <IconButton aria-label="view">
//           <ViewIcon />
//         </IconButton>
//         <IconButton aria-label="delete">
//           <DeleteIcon />
//         </IconButton>
//       </Box>

//       <Box
//         sx={{ display: "flex", alignItems: "center", mb: 2 }}
//         style={{ color: theme.palette.secondary[300] }}
//       >
//         <Typography sx={{ flex: 1 }}>OECD compliance</Typography>
//         <IconButton aria-label="upload">
//           <UploadIcon />
//         </IconButton>
//         <IconButton aria-label="download">
//           <DownloadIcon />
//         </IconButton>
//         <IconButton aria-label="view">
//           <ViewIcon />
//         </IconButton>
//         <IconButton aria-label="delete">
//           <DeleteIcon />
//         </IconButton>
//       </Box>

//       {/* Repeat similar blocks for other items... */}
//     </Box>
//   );
// };

// export default OverviewChart;


// import React, { useState } from "react";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import ViewIcon from "@mui/icons-material/Visibility";
// import { IconButton, useTheme, Dialog, DialogContent, DialogTitle } from "@mui/material";
// import { Worker, Viewer } from '@react-pdf-viewer/core';


// // Paths to each PDF file
// const pdfDocuments = {
//   financialSanctions: "/documents/TargetedFinancialSanctions.pdf",
//   sanctionsFAQs: "/documents/TFSFAQS.pdf",
//   redFlagIndicators: "/documents/RedFlagsAndSuspiciousTransactions.pdf",
//   kycPolicy: "/documents/KnowYourCustomerPolicy.pdf",
//   pepDueDiligence: "/documents/PoliticalExposedPersonsDueDiligence.pdf",
//   uboDueDiligence: "/documents/UltimateBeneficialOwnersDueDiligence.pdf",
//   complianceOfficerRoles: "/documents/ComplianceOfficerRole.pdf",
//   employeeTraining: "/documents/EmployeeTrainingProgramme.pdf",
//   entityRiskAssessment: "/documents/EntityWideRiskAssessment.pdf",
//   amlCftReview: "/documents/AMLCFTReview.pdf",
// };

// const OverviewChart = () => {
//   const theme = useTheme();
//   const [open, setOpen] = useState(false);
//   const [selectedPdf, setSelectedPdf] = useState("");

//   const handleViewClick = (url) => {
//     setSelectedPdf(url);
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setSelectedPdf("");
//   };

//   return (
// //     <Box>
// //       <Typography
// //         variant="h4"
// //         paddingBottom={2}
// //         style={{ color: theme.palette.secondary[300] }}
// //         fontWeight="bold"
// //       >
// //         Entity Overall Compliance Checks:
// //       </Typography>

// //       {[
// //   { label: "Targeted Financial Sanctions Measurements, Controls and Implementations", url: pdfDocuments.financialSanctions },
// //   { label: "Targeted Financial Sanctions (FAQs)", url: pdfDocuments.sanctionsFAQs },
// //   { label: "Sanctions Evasions, Red Flag Indicators and Reporting", url: pdfDocuments.redFlagIndicators },
// //   { label: "Know Your Customer Policy, Identifications and Due Diligence", url: pdfDocuments.kycPolicy },
// //   { label: "Political Exposed Persons Identifications and Due Diligence", url: pdfDocuments.pepDueDiligence },
// //   { label: "Ultimate Beneficial Owners Identifications and Due Diligence", url: pdfDocuments.uboDueDiligence },
// //   { label: "Compliance Officer Roles and Responsibilities", url: pdfDocuments.complianceOfficerRoles },
// //   { label: "Employee Training Programme", url: pdfDocuments.employeeTraining },
// //   { label: "Entity-Wide Risk Assessment", url: pdfDocuments.entityRiskAssessment },
// //   { label: "AML-CFT Compliance Independent Review", url: pdfDocuments.amlCftReview },
// // ].map((item, index) => (
// //         <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 2 }}>
// //           <Typography sx={{ flex: 1 }} style={{ color: theme.palette.secondary[300] }}>
// //             {item.label}
// //           </Typography>
// //           <IconButton aria-label="view" onClick={() => handleViewClick(item.url)}>
// //             <ViewIcon />
// //           </IconButton>
// //         </Box>
// //       ))}

// //       <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
// //         <DialogTitle></DialogTitle>
// //         <DialogContent>
// //           {selectedPdf && (
// //             <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>

// //               <Viewer fileUrl={selectedPdf} />
// //             </Worker>
// //           )}
// //         </DialogContent>
// //       </Dialog>
// //     </Box>

// <Box sx={({   height: "100%", // Ensure it fills the parent container
//     display: "flex",
//     flexDirection: "column",
//     overflow: "hidden",})}> 
//   <Typography
//     variant="h4"
//     paddingBottom={2}
//     style={{ color: theme.palette.secondary[300] }}
//     fontWeight="bold"
    
//   >
//     Entity Overall Compliance Checks:
//   </Typography>

//   <Box
//     sx={{
//       maxHeight: "400px", // Adjust the max height as needed
//       overflowY: "auto",
//       paddingRight: 2,
//     // Add padding for the scrollbar
//     }}
//   >
//     {[
//   { label: "Targeted Financial Sanctions Measurements, Controls and Implementations", url: pdfDocuments.financialSanctions },
//   { label: "Targeted Financial Sanctions (FAQs)", url: pdfDocuments.sanctionsFAQs },
//   { label: "Sanctions Evasions, Red Flag Indicators and Reporting", url: pdfDocuments.redFlagIndicators },
//   { label: "Know Your Customer Policy, Identifications and Due Diligence", url: pdfDocuments.kycPolicy },
//   { label: "Political Exposed Persons Identifications and Due Diligence", url: pdfDocuments.pepDueDiligence },
//   { label: "Ultimate Beneficial Owners Identifications and Due Diligence", url: pdfDocuments.uboDueDiligence },
//   { label: "Compliance Officer Roles and Responsibilities", url: pdfDocuments.complianceOfficerRoles },
//   { label: "Employee Training Programme", url: pdfDocuments.employeeTraining },
//   { label: "Entity-Wide Risk Assessment", url: pdfDocuments.entityRiskAssessment },
//   { label: "AML-CFT Compliance Independent Review", url: pdfDocuments.amlCftReview },
// ].map((item, index) => (
//       <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 2 }}>
//         <Typography sx={{ flex: 1 }} style={{ color: theme.palette.secondary[300] }}>
//           {item.label}
//         </Typography>
//         <IconButton aria-label="view" onClick={() => handleViewClick(item.url)}>
//           <ViewIcon />
//         </IconButton>
//       </Box>
//     ))}
//   </Box>

//   <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
//     <DialogTitle></DialogTitle>
//     <DialogContent>
//       {selectedPdf && (
//         <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
//           <Viewer fileUrl={selectedPdf} />
//         </Worker>
//       )}
//     </DialogContent>
//   </Dialog>
// </Box>

//   );
// };

// export default OverviewChart;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ViewIcon from "@mui/icons-material/Visibility";
import { IconButton, useTheme, Dialog, DialogContent, DialogTitle } from "@mui/material";


// Paths to each HTML file
const htmlDocuments = {
  financialSanctions: "/documents/TargetedFinancialSanctions.html",
  sanctionsFAQs: "/documents/TFSFAQS.html",
  redFlagIndicators: "/documents/redFlagIndicators.html",
  kycPolicy: "/documents/KYCS.html",
  pepDueDiligence: "/documents/PEPS.html",
  uboDueDiligence: "/documents/UBOS.html",
  complianceOfficerRoles: "/documents/ComplianceOfficerRoles.html",
  employeeTraining: "/documents/EmployeeTrainingProgramme.html",
  entityRiskAssessment: "/documents/EntityWideRiskAssessment.html",
  amlCftReview: "/documents/AMLCFTReview.html",
};

const OverviewChart = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedHtml, setSelectedHtml] = useState("");

  const handleViewClick = (url) => {
    setSelectedHtml(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedHtml("");
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", overflow: "hidden" }}>
      <Typography
        variant="h4"
        paddingBottom={2}
        style={{ color: theme.palette.secondary[300] }}
        fontWeight="bold"
      >
        Entity Overall Compliance Checks:
      </Typography>

      <Box
        sx={{
          maxHeight: "400px", // Adjust the max height as needed
          overflowY: "auto",
          paddingRight: 2, // Add padding for the scrollbar
        }}
      >
        {[
          { label: "Targeted Financial Sanctions Measurements, Controls and Implementations", url: htmlDocuments.financialSanctions },
          { label: "Targeted Financial Sanctions (FAQs)", url: htmlDocuments.sanctionsFAQs },
          { label: "Sanctions Evasions, Red Flag Indicators and Reporting", url: htmlDocuments.redFlagIndicators },
          { label: "Know Your Customer Policy, Identifications and Due Diligence", url: htmlDocuments.kycPolicy },
          { label: "Political Exposed Persons Identifications and Due Diligence", url: htmlDocuments.pepDueDiligence },
          { label: "Ultimate Beneficial Owners Identifications and Due Diligence", url: htmlDocuments.uboDueDiligence },
          { label: "Compliance Officer Roles and Responsibilities", url: htmlDocuments.complianceOfficerRoles },
          { label: "Employee Training Programme", url: htmlDocuments.employeeTraining },
          { label: "Entity-Wide Risk Assessment", url: htmlDocuments.entityRiskAssessment },
          { label: "AML-CFT Compliance Independent Review", url: htmlDocuments.amlCftReview },
        ].map((item, index) => (
          <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography sx={{ flex: 1 }} style={{ color: theme.palette.secondary[300] }}>
              {item.label}
            </Typography>
            <IconButton aria-label="view" onClick={() => handleViewClick(item.url)}>
              <ViewIcon />
            </IconButton>
          </Box>
        ))}
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle></DialogTitle>
        <DialogContent>
          {selectedHtml && (
            <iframe
              src={selectedHtml}
              width="100%"
              height="500px"
              frameBorder="0"
              title="HTML Content"
            ></iframe>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default OverviewChart;
